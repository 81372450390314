html {
  background-color: #141414;
  color: white;
  font-family: "DM Sans", sans-serif;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  body {
    overflow-x: hidden;
  }
  html {
    background-color: #141414;
  }
}

.ar {
  font-family: "Fira Code";
}

.gry {
  color: #9d9d9db7;
}

.lessdark {
  background-color: #282828;
  border-radius: 8px;
}

.shadoww {
  box-shadow: rgba(47, 47, 47, 0.2) 0px 7px 14px 0px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::placeholder,
textarea::placeholder {
  color: #9d9d9db7;
  font-family: "DM Sans";
}

.words {
  word-spacing: -4px;
}
.words2 {
  word-spacing: -6px;
}

.shadow2 {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0s linear 0.3s;
}

.menu-overlay.open {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.menu-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 80%;
  height: 80%;
  z-index: 1;
}

.faq-item {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease-out 0.3s, transform 0.5s ease-out 0.3s;
}

.faq-in-view .faq-item {
  opacity: 1;
  transform: translateY(0);
}

.faq-item2 {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease-out 0.3s, transform 0.5s ease-out 0.3s;
}

.faq-in-view2 .faq-item2 {
  opacity: 1;
  transform: translateY(0);
}

.faq-item3 {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease-out 0.3s, transform 0.5s ease-out 0.3s;
}

.faq-in-view3 .faq-item3 {
  opacity: 1;
  transform: translateY(0);
}

.faq-item4 {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease-out 0.3s, transform 0.5s ease-out 0.3s;
}

.faq-in-view4 .faq-item4 {
  opacity: 1;
  transform: translateY(0);
}


.faq-answer {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-out;
}

.modal {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in;
}

.modal-content {
  padding: 10px;
}

.modal.open {
  max-height: 500px;
}
